import { Translations } from '@aws-amplify/ui-components'
import ja from '@aws-amplify-jp/vocabulary-ja'

// パスワードポリシーに合わせる
const INVALID_PASSWORD = 'パスワードは8文字以上の英数字を指定してください'

export const vocabularies = {
  // https://github.com/aws-amplify/amplify-js/blob/main/packages/amplify-ui-components/src/common/Translations.ts
  // https://github.com/aws-amplify-jp/vocabulary-ja/blob/main/locale/ja.js
  ...ja(Translations),
  [Translations.EMPTY_USERNAME]: 'メールアドレスは空にできません', // メールアドレスをエイリアスに設定

  // https://github.com/aws-amplify/amplify-js/issues/867
  'User does not exist.': 'ユーザーが存在しません',
  'Incorrect username or password.': 'メールアドレスまたはパスワードが違います',
  'User is not confirmed.': 'ユーザーは検証されていません',
  'User already exists': 'ユーザーは既に存在します',
  'Invalid verification code provided, please try again.':
    '指定された確認コードが無効です。もう一度お試しください',
  'Invalid password format': 'パスワードのフォーマットが不正です',
  'Account recovery requires verified contact information':
    'アカウントの復元には確認済みの連絡先情報が必要です',
  'Password attempts exceeded': 'パスワード試行回数が超過しました',
  'Attempt limit exceeded, please try after some time.':
    '試行制限を超過しました。しばらくしてからもう一度お試しください',
  'Username/client id combination not found.': 'ユーザーが存在しません',
  'CUSTOM_AUTH is not enabled for the client.': 'パスワードは必須です',
  'Password does not conform to policy: Password not long enough': INVALID_PASSWORD,
  'Password does not conform to policy: Password must have uppercase characters': INVALID_PASSWORD,
  'Password does not conform to policy: Password must have lowercase characters': INVALID_PASSWORD,
  'Password does not conform to policy: Password must have numeric characters': INVALID_PASSWORD,
  "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6": INVALID_PASSWORD,
  "2 validation errors detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6; Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$": INVALID_PASSWORD,
}
