import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { ApolloLink } from '@apollo/client/link/core'
import { onError } from '@apollo/client/link/error'
import { Auth } from 'aws-amplify'
import { useMemo } from 'react'

import { API_URL } from '../config/settings'
import generatedIntrospection from '../generated/graphql'
import ErrorTracker from '../utils/ErrorTracker'

const initializeApollo = () => {
  const transactionId = Math.random().toString(36).substring(2, 11) // Sentryでトラッキングするためのランダムな文字列

  const customHeaderLink = setContext(async (_, { headers }) => {
    const session = await Auth.currentSession()
    const token = session.getIdToken().getJwtToken()
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
        'Transaction-Id': transactionId,
      },
    }
  })

  const errorTrackerLink = new ApolloLink((operation, forward) => {
    ErrorTracker.addBreadcrumb({
      category: 'GraphQL',
      message: operation.operationName,
    })
    ErrorTracker.setTag('transaction_id', transactionId) // Rails側とタグ名を合わせる必要があるためスネークケース
    ErrorTracker.setExtra('operationName', operation.operationName)
    ErrorTracker.setExtra('variables', operation.variables)
    return forward(operation)
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    // GQLのエラーフィールドをSentryに送る
    graphQLErrors?.forEach((error) => {
      ErrorTracker.trackError(error)
    })

    // サーバーから異常な値が返ったらSentryに送る
    if (
      networkError &&
      ['ServerError', 'ServerParseError', 'ClientParseError'].includes(
        networkError.name
      )
    ) {
      ErrorTracker.trackError(networkError)
    }
  })

  const httpLink = createHttpLink({
    uri: API_URL,
  })

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.from([
      customHeaderLink,
      errorTrackerLink,
      errorLink,
      httpLink,
    ]),
    cache: new InMemoryCache({
      possibleTypes: generatedIntrospection.possibleTypes,
    }),
  })
}

export const useApollo = () => useMemo(() => initializeApollo(), [])
