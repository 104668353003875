import 'antd/dist/antd.less'
import '../styles/amplify.less'
import '../styles/global.less'

import {
  EditOutlined,
  FileDoneOutlined,
  PayCircleOutlined,
  QrcodeOutlined,
  ShoppingOutlined,
  SmileOutlined,
} from '@ant-design/icons'
import { Route } from '@ant-design/pro-layout/lib/typings'
import { ApolloProvider } from '@apollo/client'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import {
  AmplifyAuthenticator,
  AmplifyForgotPassword,
  AmplifySignIn,
} from '@aws-amplify/ui-react'
import { ConfigProvider } from 'antd'
import locale from 'antd/lib/locale-provider/ja_JP'
import Amplify, { I18n } from 'aws-amplify'
import { AppProps } from 'next/dist/next-server/lib/router/router'
import Head from 'next/head'
import Router from 'next/router'
import React, { useEffect, useState } from 'react'

import { useApollo } from '../apollo/apolloClient'
import { BasicLayout } from '../components/layouts'
import { vocabularies } from '../config/i18n/amplify'
import Analytics from '../utils/Analytics'
import ErrorTracker from '../utils/ErrorTracker'

Amplify.configure({
  Auth: {
    region: process.env.NEXT_PUBLIC_AWS_REGION,
    userPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID,
    userPoolWebClientId: process.env.NEXT_PUBLIC_USER_POOL_WEB_CLIENT_ID,
  },
})

I18n.putVocabulariesForLanguage('ja', vocabularies)
I18n.setLanguage('ja')

const route: Route = {
  routes: [
    {
      path: '/',
      name: 'トップページ',
      icon: <SmileOutlined />,
    },
    {
      name: '売上確認',
      icon: <PayCircleOutlined />,
      routes: [
        {
          path: '/sales/summary',
          name: '売上サマリー',
        },
        {
          path: '/sales/daily_product_sales',
          name: '日別売上',
        },
        {
          path: '/sales/daily_ticket_usages',
          name: '日別チケット使用実績',
        },
      ],
    },
    {
      path: '/purchased_products',
      name: '購入実績',
      icon: <ShoppingOutlined />,
    },
    {
      path: '/products',
      name: '商品管理(β)',
      icon: <EditOutlined />,
    },
    {
      path: '/generate_qr',
      name: 'QRコード生成',
      icon: <QrcodeOutlined />,
    },
    {
      name: 'プレイス管理',
      icon: <FileDoneOutlined />,
      routes: [
        {
          path: '/places',
          name: 'プレイス',
        },
        {
          path: '/place_tags',
          name: 'タグ',
        },
        {
          path: '/place_specs',
          name: '設備・サービス',
        },
      ],
    },
    // NOTE: HHCTではこれ以降の機能は不要なのでコメントアウト
    // {
    //   name: '定期券',
    //   icon: <IdcardOutlined />,
    //   routes: [
    //     {
    //       name: '定期券の一覧',
    //       path: '/simple_pass/my_passes',
    //     },
    //     {
    //       name: '売上確認',
    //       path: '/simple_pass/sales_summaries',
    //     },
    //     {
    //       name: '利用者属性',
    //       path: '/simple_pass/user_demographics',
    //     },
    //     {
    //       name: '購入実績',
    //       path: '/simple_pass/purchase_details',
    //     },
    //     {
    //       name: '利用者属性',
    //       path: '/simple_pass/user_demographics',
    //     },
    //   ],
    // },
    // {
    //   name: '分析(β)',
    //   icon: <BarChartOutlined />,
    //   routes: [
    //     {
    //       path: '/analytics/user_demographics',
    //       name: '利用者属性',
    //     },
    //     {
    //       path: '/analytics/ticket_usages',
    //       name: 'チケット利用',
    //     },
    //     {
    //       path: '/analytics/product_reviews',
    //       name: 'レビュー（商品）',
    //     },
    //     {
    //       path: '/analytics/ticket_reviews',
    //       name: 'レビュー（チケット）',
    //     },
    //   ],
    // },
    // {
    //   path: '/push_notifications',
    //   name: 'PUSH通知配信(β)',
    //   icon: <NotificationOutlined />,
    // },
  ],
}

const useAuthState = () => {
  const [authState, setAuthState] = useState<AuthState>()
  const [user, setUser] = useState<{ email: string }>()
  useEffect(
    () =>
      onAuthUIStateChange((nextAuthState, authData) => {
        setAuthState(nextAuthState)
        setUser((authData as any)?.attributes)
      }),
    []
  )

  return { authState, user }
}

const useRevision = () => {
  const [isOldRevision, setIsOldRevision] = useState(false)
  useEffect(() => {
    Router.events.on('routeChangeComplete', async () => {
      const currentRevision = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA
      const result = await fetch('/revision.txt')
      const revision = await result.text()
      setIsOldRevision(currentRevision !== revision.trim())
    })
  }, [])

  return isOldRevision
}

const MyApp = ({ Component, pageProps, router }: AppProps) => {
  const apolloClient = useApollo()
  const { authState, user } = useAuthState()
  const isOldRevision = useRevision()
  useEffect(() => {
    if (isOldRevision && process.env.NODE_ENV !== 'development') {
      window.location.reload()
    }
  }, [isOldRevision, router.pathname])

  useEffect(() => {
    Analytics.init()
    const handleRouteChange = (url: string) => {
      Analytics.logPageView(url)
    }
    Router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])
  useEffect(() => {
    if (authState) {
      ErrorTracker.setUser(user || null)
    }
  }, [authState, user])

  return authState === AuthState.SignedIn && user ? (
    <ApolloProvider client={apolloClient}>
      <Head>
        <link
          rel="stylesheet"
          type="text/css"
          href="/iconFonts/ryde/css/ryde.css"
        />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        />
        <link
          href="https://cdn.jsdelivr.net/npm/@mdi/font@6.9.96/css/materialdesignicons.min.css"
          rel="stylesheet"
        />
      </Head>
      <ConfigProvider locale={locale}>
        <BasicLayout user={user} route={route}>
          <Component {...pageProps} />
        </BasicLayout>
      </ConfigProvider>
    </ApolloProvider>
  ) : (
    <AmplifyAuthenticator>
      <AmplifySignIn slot="sign-in" usernameAlias="email" hideSignUp />
      <AmplifyForgotPassword slot="forgot-password" usernameAlias="email" />
    </AmplifyAuthenticator>
  )
}

export default MyApp
