import * as Sentry from '@sentry/nextjs'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Extra } from '@sentry/types'

const trackError = (error: Error) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error(error)
    return
  }

  Sentry.captureException(error)
}

const setTag = (key: string, value: string) => {
  Sentry.configureScope((scope) => {
    scope.setTag(key, value)
  })
}

const setExtra = (key: string, value: Extra) => {
  Sentry.configureScope((scope) => {
    scope.setExtra(key, value)
  })
}

const setUser = (user: Sentry.User | null) => {
  if (user) {
    Sentry.setUser({ user })
  } else {
    // https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/identify-user/
    Sentry.configureScope((scope) => scope.setUser(null))
  }
}

export default {
  trackError,
  setTag,
  setExtra,
  setUser,
  addBreadcrumb: Sentry.addBreadcrumb,
}
